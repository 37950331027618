<template>
  <div style="background-color: #fafafa">
    <van-form @submit="onSubmit">
      <van-cell-group >

      <Picker
          name="dispatchVehicle.id"
          label="选择我关联的车辆"
          placeholder="选择我关联的车辆"
          displayField="carCodeHead"
          v-model:value="this.$data['dispatchVehicle.id']"
          :defConfig="{
               portName: 'tms.TmsVehicle.vapp.getMyDispatchCar',
           }"
      ></Picker>

          <van-button round block type="primary" native-type="submit">
            查询客户订单
          </van-button>
      </van-cell-group>
    </van-form>
<!--    到达页面-->
      <GridList title="车辆关联的客户订单" port-name="tms.transport.dispatchOrderVehicleCustom.getList"
                :request-data="{'vehicle.id': this.$data['dispatchVehicle.id'],'vapp':'vapp'}"
                :dataList="dataList" style="margin: 4.26667vw;"
                ref="grid">
        <ListCard
            v-for="item in dataList"
            :key="item.id"

            :id="item.id"
            :arriveCode="item['arriveStatusDic.code']"
            :onArrive="onArrive"
            :canArrive=true
        >
          <div class="item" >
  <!--          <van-image class="image" round  :src="require('../../assets/img/派车.png')"/>-->
            <div class="content">
              <div class="title fs16">{{ '客户订单： '+item['dispatchOrderCustomOrder.customOrder.name'] }}</div>
  <!--            <div class="tags fs12"><span style="color: #129d67">{{'派单状态: '+ item['dispatchOrder.dispatchOrderRfq.rfqStatusDic.name'] }}</span></div>-->
              <div class="time fs12">{{ '车辆运输状态： '+item['transportsStatusDic.name'] }}</div>
              <div class="time fs12">{{ '订单编号： '+item['dispatchOrderCustomOrder.customOrder.code'] }}</div>
              <div class="time fs12">{{ '起始地： '+item['nameStart'] }}</div>
              <div class="time fs12">{{ '目的地： '+item['nameEnd'] }}</div>
              <div class="time fs12">{{ '到达状态： '+item['arriveStatusDic.name'] }}</div>
  <!--            <div class="time fs12">{{ getDateStr(item['planDate'], item['startTime']) }}</div>-->
            </div>

          </div>
        </ListCard>
      </GridList>

  </div>

</template>

<script>
import ListCard from "../../components/ListCard";
import _ from "lodash";
import Picker from "../../components/Picker";
import {Dialog} from "vant";
import GridList from "../../components/GridList";
var photoJdk = {
  isSDKReady: false,
  init: function (assas) {
    var option = {
      portName: 'system.security.Security.getJsApiSignModel',
      data: {
        url: location.href
      },
      successCallback: function (data) {
        var jsApiSign = data.data.jsApiSign;
        photoJdk.initWechatJsConfig(jsApiSign);
      }
    };
    assas.$sapi.callPort(option)
  },
  //初始化微信js配置
  initWechatJsConfig: function (jsApiSign) {
    var me = this;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: jsApiSign.appID, // 必填，公众号的唯一标识
      timestamp: jsApiSign.timestamp, // 必填，生成签名的时间戳
      nonceStr: jsApiSign.nonceStr, // 必填，生成签名的随机串
      signature: jsApiSign.signature,// 必填，签名
      jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
    });
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
      // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
      // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      me.isSDKReady = true;
      console.log('JsApiJDK 签名验证正常:');
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
      // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('JsApiJDK 签名验证异常:' + JSON.stringify(res));
    });
    //wechat jdk 注册
  },
  //开始拍照 callback(serverId),serverId为多媒体ID
  startCapture: function (callback) {
    var me = this;
    if (!photoJdk.isSDKReady) {
      console.log("拍照SDK正在初始化，稍后重试");
      console.log(1)
      callback("未成功获取")
      return;
    }
    wx.getLocation({
      type: 'gcj02',
      success (res) {
        const latitude = res.latitude
        const longitude = res.longitude
        const speed = res.speed
        const accuracy = res.accuracy
        callback(latitude+","+longitude)
      }
    })
  }
}
export default {
  components: {Picker,ListCard, GridList},
  name: "VehicleReserveList",
  data() {
    return {
      visitorID: '',
      dataList: [],
      lnglat:'',
      canReport: true,
      "dispatchVehicle.id":'',
      lazyLoad:true,
    };
  },
  created: function () {
    photoJdk.init(this)
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;
  },
  methods: {
    onAdd: function () {
      this.$router.push({name: 'vehicleReserve'})
    },
    onEdit: function (id) {
        this.$router.push({name: 'commonVehicleReserve', params: {id: id}})
    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.VehicleReserve.delVehicleReserve',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    onArrive: function (id,arriveCode) {
      var me = this;
      photoJdk.startCapture(function (lnglat){
        if(arriveCode=== "LOAD_CAR_UNLOADING" || arriveCode=== "LOAD_CAR_UNLOADED"){
          return;
        }
        let value = {ids:id,
          lnglat:lnglat}
        me.$dialog.confirm({
          title: '',
          message: '是否确定到达'+(arriveCode===null?'装车点':arriveCode==='LOAD_CAR_LOADED' || arriveCode==='LOAD_CAR_LOADING'?'卸车点':null),
        })
            .then(() => {
              var option = {
                portName: 'tms.transport.dispatchOrderVehicleMaterial.arrive',
                // data: {ids: [id]},
                data: value,
                needLoading: false,
                successCallback: () => {
                  me.$notify({type: 'success', message: '确认到达'})
                  me.$refs.grid.onRefresh();
                }
              }
              me.$sapi.callPort(option);
            })
            .catch(() => {

            });
      })

    },
    onRead: function (dispatchId) {
      photoJdk.init(this)
            var option = {
              portName: 'tms.transport.dispatch.getEntity',
              data: {id: dispatchId},
              needLoading: false,
              successCallback: (data) => {
                this.$router.push({name: 'wfLog', params: {id: data.entities[0].wfProcess}})
              }
            }
            this.$sapi.callPort(option);
    },
    onChange: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定变更预约？',
      })
              .then(() => {
                var option = {
                  portName: 'osp.car.VehicleReserve.changeReserve',
                  data: {ids: [id]},
                  needLoading: false,
                  successCallback: () => {
                    this.$notify({type: 'success', message: '变更成功'});
                    this.$refs.grid.onRefresh();
                  }
                };
                this.$sapi.callPort(option);
              })
              .catch(() => {
              });
    },
    onReport: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定变更预约？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.VehicleReserve.changeReserve',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '变更成功'});
                this.$refs.grid.onRefresh();
              }
            };
            this.$sapi.callPort(option);
          })
          .catch(() => {
          });
    },
    onSubmit() {
      this.$refs.grid.onRefresh();
    },
    getTagType(code) {
      switch (code) {
        case true:
          return 'success';
        case false:
          return 'warning ';
        default:
          return 'primary';
      }
    },
    getDateStr(d, t) {
      let date = _.isEmpty(d) ? "" : d.split(' ')[0];
      let time = _.isEmpty(t) ? "" : t.split(' ')[1];
      return date+ " " + time;
    },
  }
}

</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.title {
  display: block;
  text-decoration: none;
  line-height: 1.5;
  margin: 0px 0px;
  background-image: -webkit-linear-gradient(
      left,
      #4491e8,
      #b6549a,
      #b6549a
  );
  color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: slide 5s infinite linear;
  font-size: 16px;
}

.item > .title {

}

.item .status_green {
  color: #519874;
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  width: 500px;

}

.item > .tagfs12{
  width: 135px;
}

.item .time {
  color: #666;
}
</style>
